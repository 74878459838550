import React, { FC, HTMLAttributes, MouseEventHandler } from "react";

import { GatsbyLinkProps } from "gatsby";

import { css } from "@emotion/react";
import styled from "@emotion/styled";

import { Theme } from "../../../Theme";
import { Link as Link_ } from "../../LinkComponent";
import LoadingIndicator from "../../LoadingIndicator";

const Link = Link_ as FC<GatsbyLinkProps<any>>;

const CenteredButtonContainer = styled.div`
    display: flex;
    justify-content: center;
`;

export enum ButtonColor {
    "red" = "red",
    "blue" = "blue",
    "grey" = "grey",
    "redOutline" = "redOutline",
    "blueOutline" = "blueOutline",
    "greyOutline" = "greyOutline",
}

export type ButtonColorStr = `${ButtonColor}`;

const extend =
    <k extends string | symbol, k2 extends k>() =>
    <v,>(
        r: Record<k2, v>
    ): Record<k2, v> & Partial<Record<Exclude<k, k2>, v>> =>
        r as any;

const baseStyle = (
    theme: Theme,
    color: ButtonColor | ButtonColorStr | keyof Theme["colors"]
) => {
    const buttonStyles = extend<typeof color, ButtonColorStr>()({
        red: css`
            color: white !important;
            text-decoration: none !important;

            &:hover {
                color: white;
            }
        `,

        blue: css`
            color: white !important;
            text-decoration: none !important;
        `,

        grey: css`
            color: white;
        `,

        redOutline: css`
            background-color: transparent;
        `,

        blueOutline: css`
            background-color: transparent;
        `,

        greyOutline: css`
            background-color: transparent;
        `,
    });

    return buttonStyles[color] ?? buttonStyles.red;
};

const buttonStyle = (
    theme: Theme,
    color: ButtonColor | ButtonColorStr | keyof Theme["colors"]
) => {
    const buttonStyles = extend<typeof color, ButtonColorStr>()({
        red: css`
            background-color: ${theme.colors.red.toString()};
            border-color: ${theme.colors.red.toString()};
        `,

        blue: css`
            background-color: ${theme.colors.blue.toString()};
            border-color: ${theme.colors.blue.toString()};
        `,

        grey: css`
            background-color: ${theme.colors.darkGrey.toString()};
            border-color: ${theme.colors.darkGrey.toString()};
        `,

        redOutline: css`
            color: ${theme.colors.red.toString()};
            border: 2px solid ${theme.colors.red.toString()};
        `,

        blueOutline: css`
            color: ${theme.colors.blue.toString()};
            border: 2px solid ${theme.colors.blue.toString()};
        `,

        greyOutline: css`
            color: ${theme.colors.blue.toString()};
            border-color: 2px solid ${theme.colors.darkGrey.toString()};
        `,
    });

    return buttonStyles[color] ?? buttonStyles.red;
};

export type ButtonProps = {
    isBold?: boolean;
    fontSize?: string;
    fullWidth?: boolean;
    centered?: boolean;
    underline?: boolean;
    loading?: boolean;
    disabled?: boolean;
    small?: boolean;
    extraSmall?: boolean;
} & (
    | ({ to: string } & GatsbyLinkProps<any>)
    | ({ htmlFor: string } & HTMLAttributes<HTMLLabelElement>)
    | ({
          onClick: MouseEventHandler<HTMLButtonElement>;
      } & HTMLAttributes<HTMLButtonElement>)
    | ({ disabled: true } & HTMLAttributes<HTMLButtonElement>)
    | ({
          type: "span";
      } & HTMLAttributes<HTMLSpanElement>)
    | ({
          type: "submit" | "button" | "reset";
      } & HTMLAttributes<HTMLButtonElement>)
) &
    (
        | {
              text: true;
              color?: keyof Theme["colors"];
          }
        | {
              text?: false;
              color?: ButtonColorStr;
          }
        | {
              text: boolean;
              color?: undefined;
          }
    );

const defaultProps = {
    color: "red",
    isBold: true,
    fontSize: "1rem",
    fullWidth: false,
    centered: false,
    text: false,
    small: false,
    extraSmall: false,
    underline: false,
    loading: false,
} as const;

const RawButton: FC<ButtonProps> = ({
    color,
    children,
    isBold: _,
    fontSize: _1,
    fullWidth: _2,
    centered = defaultProps.centered,
    text,
    small: _3,
    extraSmall: _4,
    underline: _5,
    loading = defaultProps.loading,
    ...props
}) => {
    const children_ = (
        <>
            {!loading && children}
            {loading && (
                <LoadingIndicator
                    light={text || !(color as ButtonColor).includes("Outline")}
                />
            )}
        </>
    );

    const button =
        "to" in props ? (
            <Link {...props}>{children_}</Link>
        ) : "htmlFor" in props ? (
            <label {...props}>{children_}</label>
        ) : (props as Extract<ButtonProps, { type: "span" }>)?.type ===
          "span" ? (
            <span {...props}>{children_}</span>
        ) : (
            <button {...props}>{children_}</button>
        );

    if (centered) {
        return <CenteredButtonContainer>{button}</CenteredButtonContainer>;
    }

    return button;
};

RawButton.defaultProps = defaultProps as any;

const Button = styled(RawButton)`
    font-size: ${({
        small = defaultProps.small,
        extraSmall = defaultProps.extraSmall,
        fontSize = defaultProps.fontSize,
    }) => (small ? "1rem" : extraSmall ? "0.75rem" : fontSize)};
    font-weight: ${({ isBold = defaultProps.isBold }) =>
        isBold ? 700 : "normal"};
    padding: 10px 50px;
    border-radius: 4px;
    border-style: solid;
    width: fit-content;
    cursor: pointer;
    text-align: center;

    &:hover {
        filter: brightness(85%);
    }

    &:disabled {
        pointer-events: none;
        opacity: 0.5;
    }

    ${({ theme }) => theme.breakpoints.upTo.small.css`
            width: 100%;
            padding: 10px 20px;
        `}

    ${({ theme, color = defaultProps.color }) => baseStyle(theme, color)}

    ${({ theme, text = defaultProps.text, color = defaultProps.color }) =>
        !text && buttonStyle(theme, color)}

    ${({ small = defaultProps.small, extraSmall = defaultProps.extraSmall }) =>
        small
            ? css`
                  padding: 5px 25px;
              `
            : extraSmall
            ? css`
                  padding: 5px 15px !important;
              `
            : false}

    ${({ fullWidth = defaultProps.fullWidth }) =>
        fullWidth &&
        css`
            display: block;
            width: 100%;
        `}

    ${({ theme, text = defaultProps.text, color = defaultProps.color }) =>
        text &&
        css`
            color: ${theme.colors[
                color as keyof Theme["colors"]
            ].toString()} !important;
            background-color: transparent !important;
            border: 0 !important;
        `}

    ${({ underline = defaultProps.underline }) =>
        underline &&
        css`
            text-decoration: underline !important;
        `}
`;
export default Button;
